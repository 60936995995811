<template>
  <full-page-template>
    <div class="bg-articles" :class="{ active: loaded }" />
    <div class="p-articles">
      <div class="container">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Статьи
          </h1>
          <div class="divider" />
          <p class="description ta--center mb-xl">
            Здесь вы можете ознакомиться с материалами, которые могут помочь вам в экстренных ситуациях
            <br />
            при потере ценностей или любимых питомцев.
          </p>
          <div
            v-masonry
            item-selector=".col"
            transition-duration="0.3s"
            :gutter="32"
            :fit-width="true"
            class="masonry mb-xl"
          >
            <div
              v-for="item in articles"
              :key="item.id"
              v-masonry-tile
              class="col"
            >
              <article-item :article="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </full-page-template>
</template>

<script>
import AppFooter from '@/components/common/AppFooter'
import FullPageTemplate from '@/layouts/FullPageTemplate'
import ArticleItem from '@/pages/articles/ArticleItem'
import articles from '@/data/articles' // Import the articles data

export default {
  name: 'Articles',
  components: {
    AppFooter,
    FullPageTemplate,
    ArticleItem
  },
  data() {
    return {
      loaded: false,
      articles // Use the imported articles data
    }
  },
  mounted() {
    if (document.readyState === 'complete') {
      this.loaded = true
    } else {
      window.onload = () => {
        this.loaded = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/* Background Styles */
.bg-articles {
    &.active {
      opacity: 1;
      transform: none;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('~@/assets/img/bg-articles.png') center / cover no-repeat;
    background-attachment: fixed;
    opacity: 0;
    transition: 1s;
  }

/* Page Container */
.p-articles {
  position: relative;
  min-height: 100vh;
  padding-top: 80px;

  .container {
    max-width: 1200px;
    margin: 2em auto;
    padding: 0 16px;
  }

  .wrapper {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    /* Section Title */
    .section-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 16px;
      color: #333;

      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }

    /* Divider */
    .divider {
      width: 60px;
      height: 4px;
      background-color: #007bff;
      margin: 16px auto;
    }

    /* Description */
    .description {
      font-size: 1.1rem;
      color: #666;
      line-height: 1.6;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        font-size: 1rem;
        padding: 0 16px;
      }
    }
  }
}
/* Masonry Grid */
.masonry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .col {
    width: 300px;
    margin: 16px;
    flex: 0 0 auto;
  }
}
</style>